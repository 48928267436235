import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, useTableSortingWrapper } from '@poly/site-ui';
import { ASC_SORT_ORDER } from '@poly/constants';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';

import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightField,
  highlightProperty,
} from '../../utils/highlight/index.js';
import { statusFormatter } from '../../util/property-utils.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  setPropertiesPaginationCurrentPage,
  setPropertiesPaginationDropdownLabel,
  setPropertiesPaginationPageSize,
  setPropertiesPaginationTotal,
} from '../../redux/actions/index.js';
import { usePropertiesListQuery } from '../../hooks/properties.js';
import usePagination from '../../hooks/usePagination.js';

function PropertiesLoader() {
  return <Loader absolute />;
}

const columns = (search) => [
  [
    'Property',
    R.identity,
    highlightProperty(search),
    R.prop('name'),
    keywordSortQuery(['name']),
  ],
  [
    'Address',
    R.path(['address', 'formatted_address']),
    highlightField(search),
    R.path(['address', 'formatted_address']),
    keywordSortQuery(['address', 'formatted_address']),
  ],
  [
    'Status',
    R.prop('status'),
    statusFormatter(search),
    R.prop('status'),
    commonSortQuery(['status']),
  ],
];

export default function (props) {
  const propertiesValue = useSelector((state) => state.properties);

  const {
    typeFilter,
    tableSearchString,
    currentPage,
    pageSize,
    paginationDropdownLabel,
  } = propertiesValue;

  const from = (currentPage - 1) * pageSize;

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: columns(tableSearchString),
    defaultOrder: ASC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { propertyLoading, properties, total, result } = usePropertiesListQuery(
    {
      forSelect: false,
      searchTerm: tableSearchString,
      from,
      sort,
      pageSize,
      typeFilter,
    },
  );

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setPropertiesPaginationTotal,
    setPageSize: setPropertiesPaginationPageSize,
    setCurrent: setPropertiesPaginationCurrentPage,
    setPaginationDropdownLabel: setPropertiesPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columnsValue = R.map(R.nth(1), columns(tableSearchString));
  const headers = R.map(R.nth(0), columns(tableSearchString));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    columns(tableSearchString),
  );
  const gridColumns = `
      minmax(100px, 500px)
      minmax(300px, 1fr)
      90px
    `;

  if (propertyLoading) return <PropertiesLoader />;
  if (!properties.length) return noDataToDisplay('Properties')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...result}
      searchTerm={tableSearchString}
      rows={properties}
      columns={columnsValue}
      headers={headers}
      formats={formats}
      size={pageSize}
      currentPage={currentPage}
      from={from}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      paginationDropdownLabel={paginationDropdownLabel}
      typeFilter={typeFilter}
      gridColumns={gridColumns}
      setSorting={setSorting}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
