import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { ASC_SORT_ORDER } from '@poly/constants';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { AbsoluteLoader, useTableSortingWrapper } from '@poly/site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { usePOsFilters } from '../../utils/purchase-orders/pos-filters.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
} from '../../utils/purchase-orders/index.js';
import {
  setApprovedPOsPaginationCurrentPage,
  setApprovedPOsPaginationDropdownLabel,
  setApprovedPOsPaginationPageSize,
  setApprovedPOsPaginationTotal,
} from '../../redux/actions/index.js';
import { usePurchaseOrdersQuery } from '../../hooks/purchase-orders.js';
import usePagination from '../../hooks/usePagination.js';

const getColumns = (search) => [
  [
    'PO#',
    R.identity,
    POFormatter(search),
    R.prop('cardNumber'),
    keywordSortQuery(['cardNumber']),
  ],
  [
    'Amount',
    R.prop('amount'),
    highlightCurrency(search),
    R.prop('amount'),
    commonSortQuery(['amount']),
  ],
  [
    'WO#',
    R.prop('project'),
    projectPOFormatter(search),
    R.path(['project', 'projectId']),
    keywordSortQuery(['projectNumber']),
  ],
  [
    'Supplier',
    R.path(['supplier', 'company', 'name']),
    highlightField(search),
    R.path(['supplier', 'company', 'name']),
    keywordSortQuery(['supplier', 'company', 'name']),
  ],
  [
    'Property',
    propertySelector,
    highlightField(search),
    R.path(['property', 'name']),
    keywordSortQuery(['property', 'name']),
  ],
  [
    'GL Code',
    R.prop('glCode'),
    highlightField(search),
    R.prop('glCode'),
    keywordSortQuery(['glCode']),
  ],
];

export default function (props) {
  const approvedPOs = useSelector((state) => state.approvedPOs);

  const {
    type,
    currentPage,
    pageSize,
    paginationDropdownLabel,
    tableSearchString,
  } = approvedPOs;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query } = usePOsFilters(approvedPOs);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: getColumns(searchTerm),
    defaultOrder: ASC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { total, requests, requestsLoading, restPurchaseOrdersProps } =
    usePurchaseOrdersQuery({
      searchTerm,
      query,
      pageSize,
      from,
      sort,
    });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setApprovedPOsPaginationTotal,
    setPageSize: setApprovedPOsPaginationPageSize,
    setCurrent: setApprovedPOsPaginationCurrentPage,
    setPaginationDropdownLabel: setApprovedPOsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(searchTerm));
  const headers = R.map(R.nth(0), getColumns(searchTerm));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(searchTerm),
  );
  const gridColumns = `
      minmax(80px, 150px)
      100px
      minmax(80px, 150px)
      minmax(150px, 1fr)
      minmax(150px, 1fr)
      100px
    `;

  if (requestsLoading) return <AbsoluteLoader />;
  if (!requests.length)
    return noDataToDisplay('Approved POs Waiting on Invoice')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restPurchaseOrdersProps}
      type={type}
      rows={requests}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      requestsLoading={requestsLoading}
      searchTerm={searchTerm}
      setSorting={setSorting}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
