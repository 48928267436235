import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { ASC_SORT_ORDER } from '@poly/constants';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { AbsoluteLoader, useTableSortingWrapper } from '@poly/site-ui';

import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightField,
  highlightSupplier,
  highlightSupplierStatus,
} from '../../utils/highlight/index.js';
import { useSuppliersFilters } from '../../utils/suppliers/suppliers-filter.js';
import usePagination from '../../hooks/usePagination.js';
import { noDataToDisplay } from '../../util/general.js';
import { useSuppliers } from '../../hooks/suppliers.js';
import {
  setSuppliersPaginationCurrentPage,
  setSuppliersPaginationDropdownLabel,
  setSuppliersPaginationPageSize,
  setSuppliersPaginationTotal,
} from '../../redux/actions/index.js';

const getColumns = (search) => [
  [
    'Supplier Name',
    R.identity,
    highlightSupplier(search),
    R.path(['company', 'name']),
    keywordSortQuery(['company', 'name']),
  ],
  [
    'Address',
    R.path(['company', 'address', 'formatted_address']),
    highlightField(search),
    R.path(['company', 'address', 'formatted_address']),
    keywordSortQuery(['company', 'address', 'formatted_address']),
  ],
  [
    'Service Types',
    R.prop('type'),
    highlightField(search),
    R.prop('type'),
    commonSortQuery(['type']),
  ],
  [
    'Status',
    R.prop('status'),
    highlightSupplierStatus(search),
    R.prop('status'),
    commonSortQuery(['status']),
  ],
];

export default function (props) {
  const suppliers = useSelector((state) => state.suppliers);

  const {
    currentPage,
    pageSize,
    paginationDropdownLabel,
    typeFilter,
    tableSearchString,
  } = suppliers;

  const from = (currentPage - 1) * pageSize;

  const { query } = useSuppliersFilters(suppliers);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: getColumns(tableSearchString),
    defaultOrder: ASC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { total, allSuppliers, supplierLoading, result } = useSuppliers({
    searchTerm: tableSearchString,
    query,
    sort,
    from,
    pageSize,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setSuppliersPaginationTotal,
    setPageSize: setSuppliersPaginationPageSize,
    setCurrent: setSuppliersPaginationCurrentPage,
    setPaginationDropdownLabel: setSuppliersPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(tableSearchString));
  const headers = R.map(R.nth(0), getColumns(tableSearchString));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(tableSearchString),
  );
  const gridColumns = `
      minmax(80px, 300px)
      minmax(150px, 1fr)
      minmax(80px, 200px)
      100px
    `;

  if (supplierLoading) return <AbsoluteLoader />;
  if (!allSuppliers.length) return noDataToDisplay('Suppliers')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...result}
      typeFilter={typeFilter}
      rows={allSuppliers}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      searchTerm={tableSearchString}
      sorting={sorting}
      setSorting={setSorting}
      valuesToSort={valuesToSort}
    />
  );
}
