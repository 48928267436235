import React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { APPROVE_PO_INVOICE_PERMISSION } from '@poly/security';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER, PurchaseOrderStatus } from '@poly/constants';
import { AbsoluteLoader, useTableSortingWrapper } from '@poly/site-ui';

import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { ApproveInvoiceError } from '../../constants/alerts.js';
import TableCheckbox from '../../components/checkboxes/table-checkbox.js';
import HeaderCheckbox from '../../components/checkboxes/header-checkbox.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { useInvoicesFilters } from '../../utils/invoices/invoices-filters.js';
import { InvoiceFormatter } from '../../utils/invoices/index.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
  scopeFormatter,
} from '../../utils/purchase-orders/index.js';
import {
  setInvoicesPaginationCurrentPage,
  setInvoicesPaginationDropdownLabel,
  setInvoicesPaginationPageSize,
  setInvoicesPaginationTotal,
  setSelectedInvoices,
  toggleInvoice,
} from '../../redux/actions/index.js';
import { noDataToDisplay } from '../../util/general.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import { useInvoicesQuery } from '../../hooks/invoices.js';
import usePagination from '../../hooks/usePagination.js';

const STATE_PATH = ['invoices', 'selectedInvoices'];
const INVOICE_OPEN_STATUS = 'Open';

const getColumns = ({
  invoices,
  dispatch,
  tableSearchString: search,
  user,
  getInvoiceStatus,
}) => {
  const commonProps = {
    disabled: !checkPermissionByOneAccessItem(
      APPROVE_PO_INVOICE_PERMISSION,
      user,
      R.pick(['clientId'], user),
    ),
    showErrorMessage: () => toast.error(ApproveInvoiceError),
  };
  return [
    [
      <HeaderCheckbox
        statePath={STATE_PATH}
        pageItems={invoices.map(({ _id }) => _id)}
        onChange={R.compose(dispatch, setSelectedInvoices)}
        {...commonProps}
      />,
      R.prop('_id'),
      (id) => (
        <TableCheckbox
          statePath={STATE_PATH}
          onChange={R.compose(dispatch, toggleInvoice)}
          id={id}
          {...commonProps}
        />
      ),
    ],
    [
      'PO #',
      R.prop('purchaseOrder'),
      POFormatter(search, getInvoiceStatus),
      R.path(['purchaseOrder', 'cardNumber']),
      keywordSortQuery(['purchaseOrder', 'cardNumber']),
    ],
    [
      'Invoice #',
      R.identity,
      InvoiceFormatter(search),
      R.prop('invoiceNumber'),
      keywordSortQuery(['invoiceNumber']),
    ],
    [
      'Amount',
      R.prop('total'),
      highlightCurrency(search),
      R.prop('total'),
      commonSortQuery(['total']),
    ],
    [
      'Scope',
      R.path(['purchaseOrder', 'isInScope']),
      scopeFormatter(search),
      R.path(['purchaseOrder', 'isInScope']),
      commonSortQuery(['purchaseOrder', 'isInScope']),
    ],
    [
      'WO #',
      R.prop('project'),
      projectPOFormatter(search),
      R.path(['project', 'projectId']),
      keywordSortQuery(['projectNumber']),
    ],
    [
      'Supplier',
      R.path(['supplier', 'company', 'name']),
      highlightField(search),
      R.path(['supplier', 'company', 'name']),
      keywordSortQuery(['supplier', 'company', 'name']),
    ],
    [
      'Property',
      propertySelector,
      highlightField(search),
      R.path(['property', 'name']),
      keywordSortQuery(['property', 'name']),
    ],
  ];
};

// getInvoiceStatus :: Invoice -> String
const getInvoiceStatus = (purchaseOrder) =>
  R.path(['searchPOInvoices', 'total'], purchaseOrder) > 0 &&
  R.prop('status', purchaseOrder) !== PurchaseOrderStatus.CLOSED &&
  INVOICE_OPEN_STATUS;

export default function InvoicesList(props) {
  const dispatch = useDispatch();

  const invoicesState = useSelector((state) => state.invoices);
  const user = useSelector((state) => state.user);

  const {
    type,
    currentPage,
    pageSize,
    paginationDropdownLabel,
    tableSearchString,
  } = invoicesState;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query, size } = useInvoicesFilters(invoicesState);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    defaultOrder: ASC_SORT_ORDER,
    defaultColumn: 2,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
    columnsToSkipSorting: [0],
    tableConfig: getColumns({
      invoices: [],
      dispatch,
      tableSearchString,
      user,
      getInvoiceStatus,
    }),
  });

  const { invoices, total, invoicesLoading, restInvoicesProps } =
    useInvoicesQuery({ searchTerm, query, pageSize: size, from, sort });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setInvoicesPaginationTotal,
    setPageSize: setInvoicesPaginationPageSize,
    setCurrent: setInvoicesPaginationCurrentPage,
    setPaginationDropdownLabel: setInvoicesPaginationDropdownLabel,
    total,
    currentPage,
  });

  const cols = getColumns({
    invoices,
    dispatch,
    tableSearchString,
    user,
    getInvoiceStatus,
  });

  const columns = R.map(R.nth(1), cols);
  const headers = R.map(R.nth(0), cols);
  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);

  const gridColumns = `
        16px
        minmax(80px, 150px)
        minmax(80px, 150px)
        100px
        100px
        minmax(80px, 150px)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
      `;

  if (invoicesLoading) return <AbsoluteLoader />;
  if (!invoices.length) return noDataToDisplay('Invoices Pending Approvals')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restInvoicesProps}
      user={user}
      type={type}
      rows={invoices}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      getInvoiceStatus={getInvoiceStatus}
      invoicesLoading={invoicesLoading}
      searchTerm={searchTerm}
      sorting={sorting}
      setSorting={setSorting}
      valuesToSort={valuesToSort}
    />
  );
}
