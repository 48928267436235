import {
  func,
  shape,
  string,
  object,
  arrayOf,
  objectOf,
  oneOfType,
} from 'prop-types';
import React from 'react';
import { projectStatusUITransform, PageHeader } from '@poly/site-ui';
import { BreadCrumbs, HeadingH5, Widget, Grid, S } from '@poly/site-book';

import { EditResponseTeamIcon } from '../project/details/index.js';
import EditResponseTeamContainer from '../../containers/project/details/response-team/edit.js';
import {
  getTechnicianTypeByUser,
  mrPmProjectFormatter,
  propertyFormatter,
} from '../../util/project-utils.js';
import { noDataToDisplay, isExist } from '../../util/general.js';
import {
  SupplierTeamMember,
  UserTeamMember,
  AddressRow,
  DetailsRow,
} from '../../utils/wrappers.js';

function ProjectDetails({
  openModal,
  project,
  project: {
    description,
    details,
    location,
    property,
    addressParts,
    client,
    startDate,
    endDate,
    maintenancePlan,
    manager,
    technicians,
    suppliers,
  },
}) {
  return (
    <>
      <Widget.Item>
        <PageHeader>
          <HeadingH5>PM Details</HeadingH5>
          {projectStatusUITransform(project)}
        </PageHeader>
        <Grid columns="100px 1fr" simple>
          <DetailsRow title="Description" value={description} />
          <DetailsRow title="Details" value={details} withEditor />
          <DetailsRow title="Location" value={location} />
          <DetailsRow title="Property" value={propertyFormatter(property)} />
          <AddressRow title="Address" value={addressParts} />
          <DetailsRow title="Client" value={client} />
          <Grid.Row>
            <Grid.Cell vertical="center">
              <S type="title">Start & End</S>
            </Grid.Cell>
            <Grid.Cell>
              <BreadCrumbs>
                <BreadCrumbs.Item>
                  <S type="content" bold>
                    {startDate || '—'}
                  </S>
                </BreadCrumbs.Item>
                <BreadCrumbs.Item>
                  <S type="content" bold>
                    {endDate || '—'}
                  </S>
                </BreadCrumbs.Item>
              </BreadCrumbs>
            </Grid.Cell>
          </Grid.Row>
          <DetailsRow
            title="Master WO"
            value={mrPmProjectFormatter(maintenancePlan)}
          />
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <PageHeader>
          <HeadingH5>Response Team</HeadingH5>
          <EditResponseTeamIcon {...{ openModal }} />
          <EditResponseTeamContainer />
        </PageHeader>
        <Grid columns="repeat(2, minmax(100px, 1fr))" simple>
          {[manager, technicians, suppliers].some(isExist) ? (
            <>
              {isExist(manager) && (
                <UserTeamMember member={manager} type="Manager" />
              )}
              {suppliers.map((supplier) => (
                <SupplierTeamMember
                  member={supplier}
                  key={supplier._id}
                  type="Supplier"
                />
              ))}
              {technicians.map((technician) => (
                <UserTeamMember
                  member={technician}
                  key={technician._id}
                  type={getTechnicianTypeByUser(technician)}
                />
              ))}
            </>
          ) : (
            noDataToDisplay('Members')()
          )}
        </Grid>
      </Widget.Item>
    </>
  );
}

ProjectDetails.propTypes = {
  openModal: func.isRequired,
  project: shape({
    status: string,
    description: string,
    details: oneOfType([string, object]),
    location: string,
    addressParts: objectOf(string),
    property: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    client: string,
    startDate: string,
    endDate: string,
    maintenancePlan: shape({
      maintenancePlanId: string.isRequired,
    }).isRequired,
    manager: shape({
      name: string,
      phone: string,
      email: string,
    }),
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
      }).isRequired,
    ),
    technicians: arrayOf(
      shape({
        _id: string.isRequired,
      }).isRequired,
    ),
  }).isRequired,
};

export default ProjectDetails;
